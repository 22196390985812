import preparedReducer from './reducer';
import {
  requestTours,
  requestTour,
  createTour,
  updateTour,
  enableTour,
  disableTour,
  deleteTour,
} from './actions';

export const reducer = preparedReducer;

export const SLICE_NAME = 'tours';

export const selectors = (state) => state[SLICE_NAME];
export const selectToursPerSite = (state) => state[SLICE_NAME].list;

export const actions = {
  requestTours,
  requestTour,
  createTour,
  updateTour,
  deleteTour,
  enableTour,
  disableTour,
};
