import { createAction, createFailure, createRequest, createSuccess } from 'ducks/helpers';
import constants from './constants';

export const biToursSummaryRequest = createRequest(constants.biToursSummary);
export const biToursSummarySuccess = createSuccess(constants.biToursSummary);
export const biToursSummaryFailure = createFailure(constants.biToursSummary);

export const requestBIToursSummary = biToursSummaryRequest;


export const biToursOverviewRequest = createRequest(constants.biToursOverview);
export const biToursOverviewSuccess = createSuccess(constants.biToursOverview);
export const biToursOverviewFailure = createFailure(constants.biToursOverview);

export const requestBIToursOverview = biToursOverviewRequest;

export const biToursSiteActivityRequest = createRequest(constants.biToursSiteActivity);
export const biToursSiteActivitySuccess = createSuccess(constants.biToursSiteActivity);
export const biToursSiteActivityFailure = createFailure(constants.biToursSiteActivity);

export const requestBIToursSiteActivity = biToursSiteActivityRequest;

export const biToursByTourRequest = createRequest(constants.biToursByTour);
export const biToursByTourSuccess = createSuccess(constants.biToursByTour);
export const biToursByTourFailure = createFailure(constants.biToursByTour);

export const requestBIToursByTour = biToursByTourRequest;

export const biToursByEmployeeRequest = createRequest(constants.biToursByEmployee);
export const biToursByEmployeeSuccess = createSuccess(constants.biToursByEmployee);
export const biToursByEmployeeFailure = createFailure(constants.biToursByEmployee);

export const requestBIToursByEmployee = biToursByEmployeeRequest;

export const biToursByStatusRequest = createRequest(constants.biToursByStatus);
export const biToursByStatusSuccess = createSuccess(constants.biToursByStatus);
export const biToursByStatusFailure = createFailure(constants.biToursByStatus);

export const requestBIToursByStatus = biToursByStatusRequest;

export const setLoadingState = createAction(constants.setLoadingState);
